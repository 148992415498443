<!-- 产品追溯表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 产品名称 -->
      <FormItem>
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_name" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in productList" :value="item.product_name" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 注册证号 -->
      <FormItem class="marginLeft40">
        <span>注册证号/备案凭证号：</span>
        <Select class="iviewIptWidth250" v-model="queryFrom.licence_code" clearable filterable>
          <Option v-for="(item, index) in licence_codeList" :value="item.licence_code" :key="index">{{ item.licence_code }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft60">
        <span>时间：</span>
        <DatePicker type="month" placeholder="请选择" :value="queryFrom.begin_time" @on-change="changeTime($event, 1)" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="month" placeholder="请选择" :options="options" :value="queryFrom.end_time" @on-change="changeTime($event, 2)" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 按钮 -->
      <!-- <FormItem class="po-create-number marginRight0" :label-width="10"> -->
      <FormItem>
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table @on-sort-change="sortHospital" :productList="listColumns" :productData="listData" border show-summary :summary-method="handleSummary" :pages="queryFrom" :total="total" totalText="条记录" :isLoad="isLoad" @change-page="changePage"></Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'monthlyProductStatistics',
  components: {
    Table,
  },
  data() {
    return {
      Loagin: false,
      purchase_quantity_tatol: '',
      profit_total: '',
      purchase_amount_tatol: '',
      sale_amount_tatol: '',
      sale_quantity_tatol: '',
      supplied_varieties_tatol: '',
      total_no_tax_purchase_amount: '',
      total_no_tax_sale_amount: '',
      licence_codeList: [],
      options: {},
      tabloading: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          key: 'index',
          align: 'center',
          minWidth: 60,
          maxWidth: 70,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 130,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 140,
        },
        {
          title: '供货总量',
          key: 'purchase_quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '供货金额',
          key: 'purchase_amount',
          align: 'center',
          sortable: 'custom',
          minWidth: 100,
          render: (h, param) => {
            let money = param.row.purchase_amount.toFixed(2)
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
        },
        {
          title: '供货不含税金额',
          key: 'no_tax_purchase_amount',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.no_tax_purchase_amount ? '￥' + param.row.no_tax_purchase_amount : 0)])
          },
        },
        {
          title: '采购比重',
          // key: '',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let money
            if (!param.row.purchase_quantity) {
              money = ''
            } else {
              money = ((Number(param.row.purchase_quantity) / this.purchase_quantity_tatol) * 100).toFixed(4)
            }
            money = money ? money : 0
            return h('span', money + '%')
          },
        },
        {
          title: '销售总量',
          key: 'sale_quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '销售金额',
          key: 'sale_amount',
          sortable: 'custom',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let money = param.row.sale_amount.toFixed(2)
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
        },
        {
          title: '销售不含税金额',
          key: 'no_tax_sale_amount',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.no_tax_sale_amount ? '￥' + param.row.no_tax_sale_amount : 0)])
          },
        },
        {
          title: '利润',
          key: 'profit',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.profit ? '￥' + param.row.profit : 0)])
          },
        },
        {
          title: '利润率',
          key: 'profit_margin',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 70,
          maxWidth: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.getDetailsData(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 产品下拉
      productList: [],
      // 加载中
      isLoad: false,
      // 总条数
      total: 0,
      // 查询参数
      queryFrom: {
        page: 1,
        rows: 10,
        licence_code: '',
        product_name: '',
        batch_number: '',
        product_model_code: null,
      },
    }
  },
  mounted() {
    this.getlisence()
    this.getProductManageSearchProduct()
  },
  methods: {
    // 数字转金额处理
    formatMoney(totalNum) {
      // if (typeof totalNum === 'number') {
      //   return totalNum + '.00'
      // } else {
      //   return totalNum
      // }
      let money = 0
      if (totalNum >= 0) {
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      } else {
        totalNum = Math.abs(totalNum)
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
        money = '-' + money
      }
      return money
    },
    sortHospital(data) {
      if (data.order == 'desc') {
        this.queryFrom.sort_type = '1'
        this.queryFrom.sort_str = data.key
      } else if (data.order == 'asc') {
        this.queryFrom.sort_type = '2'
        this.queryFrom.sort_str = data.key
      } else {
        this.queryFrom.sort_type = ''
        this.queryFrom.sort_str = ''
      }
      this.getProductTrace()
    },
    Export() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      this.$http.downFile(this.$apiConsign.exportProductSupply, this.queryFrom, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '产品月度统计.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          let v = ''
          if (key === 'purchase_quantity' || key === 'profit' || key === 'purchase_amount' || key === 'sale_amount' || key === 'sale_quantity' || key === 'supplied_varieties' || key === 'no_tax_purchase_amount' || key === 'no_tax_sale_amount') {
            if (key === 'purchase_quantity') {
              v = this.purchase_quantity_tatol
            }
            if (key === 'profit') {
              v = '¥' + this.profit_total
            }
            if (key === 'purchase_amount') {
              v = '¥' + this.purchase_amount_tatol
            }
            if (key === 'sale_amount') {
              v = '¥' + this.sale_amount_tatol
            }
            if (key === 'sale_quantity') {
              v = this.sale_quantity_tatol
            }
            if (key === 'no_tax_purchase_amount') {
              v = '¥' + this.total_no_tax_purchase_amount
            }
            if (key === 'no_tax_sale_amount') {
              v = '¥' + this.total_no_tax_sale_amount
            }
          }
          sums[key] = {
            key,
            value: v,
          }
        } else {
          let v = ''
          if (key === 'purchase_quantity') {
            v = this.purchase_quantity_tatol
          }
          if (key === 'profit') {
            v = '¥' + this.profit_total
          }
          if (key === 'purchase_amount') {
            v = '¥' + this.purchase_amount_tatol
          }
          if (key === 'sale_amount') {
            v = '¥' + this.sale_amount_tatol
          }
          if (key === 'sale_quantity') {
            v = this.sale_quantity_tatol
          }
          if (key === 'no_tax_purchase_amount') {
            v = '¥' + this.total_no_tax_purchase_amount
          }
          if (key === 'no_tax_sale_amount') {
            v = '¥' + this.total_no_tax_sale_amount
          }
          sums[key] = {
            key,
            value: v,
          }
        }
      })
      return sums
    },
    getlisence() {
      this.$http.get(this.$apiConsign.productLicenceList, {}, true).then(res => {
        this.licence_codeList = res.data
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.queryFrom.begin_time = e
        // 清空截止日期
        if (e > this.queryFrom.end_time) {
          this.queryFrom.end_time = ''
        }
        this.options = {
          disabledDate(date) {
            return date.valueOf() < new Date(that.queryFrom.begin_time) - 86400000
          },
        }
      } else {
        this.queryFrom.end_time = e
      }
    },
    // 查询
    query() {
      this.queryFrom.page = 1
      this.getProductTrace()
    },
    // 产品下拉
    getProductManageSearchProduct() {
      this.$http.get(this.$apiConsign.productNameList).then(res => {
        this.productList = res.data
      })
    },
    // 获取详情数据
    getDetailsData(row) {
      this.$router.push({
        path: '/monthlyProductStatisticsDetailConsign',
        query: {
          product_name: row.product_name,
          licence_code: row.licence_code,
          end_time: this.queryFrom.end_time ? this.queryFrom.end_time : '',
          begin_time: this.queryFrom.begin_time ? this.queryFrom.begin_time : '',
        },
      })
    },
    // 翻页
    changePage(e) {
      this.queryFrom.page = e
      this.getProductTrace()
    },
    // 获取数据
    getProductTrace() {
      this.tabloading = true
      this.$http.get(this.$apiConsign.productSupply, this.queryFrom, false).then(res => {
        this.tabloading = false
        this.listData = res.data.result
        this.total = res.data.total
        // this.listData.forEach(item => {
        // item.purchase_amount = item.purchase_amount ? Number(item.purchase_amount.replace(/,/g, '')) : 0
        // item.sale_amount = item.sale_amount ? Number(item.sale_amount.replace(/,/g, '')) : 0
        // })
      })
      this.$http.get(this.$apiConsign.ProductSupplyTotal, this.queryFrom, false).then(res => {
        this.purchase_quantity_tatol = res.data.purchase_quantity_tatol
        this.profit_total = res.data.profit_total
        this.purchase_amount_tatol = res.data.purchase_amount_tatol
        this.sale_amount_tatol = res.data.sale_amount_tatol
        this.sale_quantity_tatol = res.data.sale_quantity_tatol
        this.supplied_varieties_tatol = res.data.supplied_varieties_tatol
        this.total_no_tax_purchase_amount = res.data.total_no_tax_purchase_amount
        this.total_no_tax_sale_amount = res.data.total_no_tax_sale_amount
      })
    },
  },
}
</script>

<style scoped lang="less">
.modal {
  color: #333333;
  .header {
    font-size: 18px;
    font-weight: bold;
  }
  .body {
    p {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    .info {
      display: flex;
      .left {
        flex: 6;
      }
      .right {
        flex: 4;
      }
      .item {
        display: flex;
        font-size: 16px;
        line-height: 36px;
        margin-bottom: 20px;
        .title {
          min-width: 70px;
          text-align-last: justify;
        }
      }
    }
  }
}
</style>
